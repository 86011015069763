<template>
  <div class="account">
    <van-nav-bar
      :title="$t('alllogins')"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <van-radio-group v-model="radio">
      <van-cell-group>
        <van-cell
          v-for="(item, index) in account"
          :key="index"
          :title="item"
          clickable
          @click="onRadio(index, item)"
        >
          <template #right-icon>
            <van-radio :name="`${index + 1}`" checked-color="#13b5b1" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-radio-group>
  </div>
</template>

<script>
import { getUserLogins } from "@/api";
import { Dialog } from "vant";
export default {
  name: "Account",
  data() {
    return {
      account: [],
      radio: "",
    };
  },
  mounted() {
    this.getAccount();
  },
  methods: {
    async getAccount() {
      let res = await getUserLogins({});
      console.log(res);
      if (res.code == 200) {
        this.account = res.msg;
      } else {
        Dialog({ message: res.code });
      }
    },
    onRadio(index, account) {
      this.radio = `${index + 1}`;
      // this.$router.push({
      //   name: "/Underling",
      //   params: {
      //     data: account,
      //   },
      // });
      localStorage.setItem("account_chose", account);
      this.$router.push({
        name: "/Underling",
      });
    },
  },
};
</script>

<style>
</style>